import { Modal } from "antd";
import jwt from "expo-jwt";
import moment from "moment";
import qs from "qs";
import React from "react";
import { toast } from "react-hot-toast";
import PsLabel from "./PsLabel";
import PsSelect from "./PsSelect";
import PsTextSearchBox from "./PsTextSearchBox";
import PrintHeaderWithLogo from "./PrintHeaderWithLogo";
import PsCourseListDropdown from "./PsCourseListDropdown";
import PsSectionListDropdown from "./PsSectionListDropdown";
import PsImageSelect from "./PsImageSelect";
import * as cusotmCssStyles from "../style";

export {
  PsLabel,
  PsSelect,
  PsTextSearchBox,
  PrintHeaderWithLogo,
  PsCourseListDropdown,
  PsSectionListDropdown,
  PsImageSelect,
};

export const PS_WEB = "21R91541sD5411H0G5";

//testing github karan2
export const appName = "KalviSalai";
export const appTittle = "College ERP";

export const SITE_FILE_DOWNLOAD_DIR = "files_list/";
export const CUB_LOGO = false;

//College Demo - Peace Soft
/*export const baseUrl = "http://localhost/git/svncollege/api/";
//export const baseUrl = "https://collegeerp.kalvisalai.in/api/";
export const siteUrl = "https://collegeerp.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "v2demo/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "clgerp"; // */

// RAC Womens College - Thiruvarur
/*export const baseUrl = "https://racollege.kalvisalai.in/manage/api/";
export const siteUrl = "https://racollege.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "racwomen/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "rac"; // */

// Sasurie College
/*export const baseUrl = "https://portal.sasurie.com/api/";
export const siteUrl = "https://portal.sasurie.com/";
export const S3_BUCKET_HOME_PATH = "sasurie/"; //end trailing slash is must
export const APP_TYPE = "SAAS";
export const DB_POSTFIX = "sasurie"; // */

// Government Law College - Thrichy
/*export const baseUrl = "https://glctry.tndls.ac.in/api/";
export const siteUrl = "https://glctry.tndls.ac.in/";
export const S3_BUCKET_HOME_PATH = "trichy/"; //end trailing slash is must
export const APP_TYPE = '';
export const DB_POSTFIX = "glctry"; //*/

// SVN College - Madurai
/* export const baseUrl = "https://svncollege.kalvisalai.in/api/";
export const siteUrl = "https://svncollege.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "svn/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "svn"; // */

//SARAHTUCKER COLLEGE - TVL
/*export const baseUrl = "https://portal.sarahtuckercollege.edu.in/api/";
export const siteUrl = "https://sarahtuckercollege.edu.in/";
export const S3_BUCKET_HOME_PATH = "stc/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "stc"; //*/

//St.Johns College - Tirunelveli
/*export const baseUrl = "https://stjohnscollege.kalvisalai.in/api/";
export const siteUrl = "https://stjohnscollege.edu.in/";
export const S3_BUCKET_HOME_PATH = "stjohns/"; //end trailing slash is mustb
export const APP_TYPE = "";
export const DB_POSTFIX = "stjohns"; // */

//ST-GROUP(Thangapazham)

/*export const baseUrl = "https://stgroup.kalvisalai.in/api/";
export const siteUrl = "https://stgroup.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "stgroup/"; //end trailing slash is must
export const APP_TYPE = "SAAS";
export const DB_POSTFIX = "stgroup"; // */

//TNSOEL - CHENNAI (LAW UNIVERSITY)
/*export const baseUrl = "https://soelerp.tndalu.ac.in/api/";
export const siteUrl = "https://soelerp.tndalu.ac.in/";
export const S3_BUCKET_HOME_PATH = "soel/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "soel"; // */

//TNDLS - TAMIL NADU LAW COLLEGE SAAS
/*export const baseUrl = "https://portal.tndls.ac.in/api/";
export const siteUrl = "https://portal.tndls.ac.in/";
export const S3_BUCKET_HOME_PATH = "tndls/"; //end trailing slash is must
export const APP_TYPE = "SAAS";
export const DB_POSTFIX = "tndls"; // */

//TNDLS - TAMIL NADU LAW COLLEGE ADMIN
/*export const baseUrl = "https://portal.tndls.ac.in/admin/api/";
export const siteUrl = "https://portal.tndls.ac.in/admin/";
export const S3_BUCKET_HOME_PATH = "dls/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "dls"; // */

//TNDLS - TAMIL NADU LAW COLLEGE SAAS
/*export const baseUrl = "https://tndls.kalvisalai.in/api/";
export const siteUrl = "https://tndls.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "tndls/"; //end trailing slash is must
export const APP_TYPE = "SAAS";
export const DB_POSTFIX = "tndls"; // */

//Nagarathinam Angalammal Arts and Science college
/*export const baseUrl = "https://naasmadurai.kalvisalai.in/api/";
export const siteUrl = "https://naasmadurai.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "naas/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "naas"; // */

//Sri Parasakthi College for Women
/*export const baseUrl = "https://sriparasakthicollege.kalvisalai.in/apiv2/";
export const siteUrl = "https://sriparasakthicollege.edu.in/";
export const S3_BUCKET_HOME_PATH = "sriparas/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "sriparas";

//Balagan Saraswathi Arts and Science college for women
/*export const baseUrl = "https://bsaswomen.kalvisalai.in/api/";
export const siteUrl = "https://bsaswomen.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "bsaswomen/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "bsaswomen"; // */

//  ANNAI GROUP OF INSTITUTIONS - SAAS
/* export const baseUrl = "https://agi.kalvisalai.in/api/";
export const siteUrl = "https://agi.kalvisalai.in/";
export const S3_BUCKET_HOME_PATH = "annaigi/"; //end trailing slash is must
export const APP_TYPE = "SAAS";
export const DB_POSTFIX = "annaigi"; */

//SPORTS UNIVERSITY COLLEGE
/*export const baseUrl = "https://erp.tnpesuonline.in/api/";
export const siteUrl = "https://erp.tnpesuonline.in/";
export const S3_BUCKET_HOME_PATH = "erptnpesu/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "erptnpesu"; // */

//St.Xavier college - Palayamkottai
/*export const baseUrl = "https://stxavierstn.in/api/";
export const siteUrl = "https://stxavierstn.in/";
export const S3_BUCKET_HOME_PATH = "sxctvl/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; // */

//DBC_Maram Manipoor - Donbosco College
/*export const baseUrl = "https://dbcmaram.kalvisalai.in/api/";
export const siteUrl = "https://dbcmaram.ac.in/";
export const S3_BUCKET_HOME_PATH = "dbcmaram/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = ""; // */

// SLCS - Madurai (NAAC ONLY)
//export const baseUrl = "https://naac.slcs.edu.in/api/";
/*export const baseUrl = "https://portal.slcs.edu.in/api/";
export const siteUrl = "https://slcs.edu.in/";
export const S3_BUCKET_HOME_PATH = "slcs/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = ""; // */

// NSCAS - THENI
/*export const baseUrl = "https://erp.nscollege.org.in/api/";
export const siteUrl = "https://nscollege.org.in/";
export const S3_BUCKET_HOME_PATH = "nscas/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = ""; // */

// TDMNS - NEW FULL ERP (V2)
/*export const baseUrl = "https://tdmns.kalvisalai.in/v2api/";
export const siteUrl = "https://tdmnscollege.edu.in/";
export const S3_BUCKET_HOME_PATH = "tdmns/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = ""; // */

// KAMARAJ COLLEGE - TUTUCORIN
/*export const baseUrl = "https://portal.kamarajcollege.ac.in/api/";
export const siteUrl = "https://portal.kamarajcollege.ac.in/";
export const S3_BUCKET_HOME_PATH = "kctuty/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = ""; // */

// Christopher College
/*export const baseUrl = "https://christophercollege.kalvisalai.in/apiv3/";
export const siteUrl = "https://christophercollege.edu.in/";
export const S3_BUCKET_HOME_PATH = "christo/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; //*/

//St.Jerome's College - Nagercoil
export const baseUrl = "https://portal.stjeromes.in/api/";
export const siteUrl = "https://portal.stjeromes.in/";
export const S3_BUCKET_HOME_PATH = "stjeromes/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; //*/

//Thiruvalluvar College - Papanasam
/*export const baseUrl = "https://thiruvalluvarcollege.kalvisalai.in/manage/api/";
export const siteUrl = "https://thiruvalluvarcollege.net/";
export const S3_BUCKET_HOME_PATH = "tvcp/"; //end trailin  g slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; //*/

//GVN Institute of Medical Science - Trichy
/*export const baseUrl = "https://portal.gvnims.edu.in/api/";
export const siteUrl = "https://gvnims.edu.in/";
export const S3_BUCKET_HOME_PATH = "gvnims/"; //end trailing slash is must
export const APP_TYPE = "MULTICLG";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; //*/

// ANJAC COLLEGE - SIVAKASI
/*export const baseUrl = "https://irms.anjac.edu.in/api/";
export const siteUrl = "https://anjac.edu.in/";
export const S3_BUCKET_HOME_PATH = "anjac/"; //end trailing slash is must
export const APP_TYPE = "";
export const DB_POSTFIX = "";
export const DENOMINATION = ""; // */

export const DEVELOPER = {
  COMPANY_NAME: "Peace Soft Technologies",
  COMPANY_URL: "https://peacesoft.in",
  APP_NAME: "Kalvisalai College Edition",
  APP_VERSION: "1.0",
  SUPPORT_MOBILE: "+91 9487900120",
  SUPPORT_EMAIL: "peacesoft.developer@gmail.com",
};

export const CUSTOM_ICONS = {
  CUB_LOGO: "",
  NO_IMAGE: require("../assets/images/noimage.png"),
  EMPTY_STATE: require("../assets/images/SearchEmptyState.svg"),
  NO_ITEMS: require("../assets/images/noitems.svg"),
};

export const SALUTATIONS = [
  "Mr",
  "Mrs",
  "Miss",
  "Ms",
  "Dr",
  "Rev. Fr",
  "Rev. Fr. Dr",
  "Sis",
  "Sis. Dr",
];

export const ADMISSION_TYPE = [
  "regular",
  "lateral-entry",
  "re-admission",
  "repeat-semester",
];

export const sleep = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const psConfirm = (
  successFunction,
  title = "Confirm",
  text = "Do you want to save",
  okText = "Yes",
  cancelText = "No"
) => {
  const { confirm } = Modal;

  let htext = text;
  if (typeof text == "object") {
    htext = text.props.children;
  }

  confirm({
    title: title,
    content: <div dangerouslySetInnerHTML={{ __html: htext }} />,
    cancelText: cancelText,
    okText: okText,
    onOk() {
      successFunction();
    },
    onCancel() {
      return false;
    },
    width: 600,
  });
};

export const psAlert = (
  title = "Alert",
  text = "Do you want to save",
  width = 500
) => {
  let htext = text;
  if (typeof text == "object") {
    htext = text.props.children;
  }

  Modal.warning({
    title: title,
    content: <div dangerouslySetInnerHTML={{ __html: htext }} />,
    width: width,
    style: {
      top: 20,
    },
  });
};

export const CardFixedTop = ({ title, breadcrumb, children }) => {
  return (
    <div className="card card-fixed-top">
      <div className="card-body">
        <div className="d-flex  justify-content-between">
          <div style={{ padding: "2px 0 0 8px" }}>
            {breadcrumb ? (
              <ol className="breadcrumb mb-0">{breadcrumb}</ol>
            ) : (
              <b>{title}</b>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const getFileLiveUrl = (item, originalImage = false) => {
  let url = siteUrl + SITE_FILE_DOWNLOAD_DIR;
  if (item && item.Key) url += item.Key;
  else url += item;
  let ext = getFileExtension(url);
  ext = lowerCase(ext);
  if (["png", "jpg", "jpeg", "bmp", "gif"].includes(ext))
    if (!originalImage) url = url.replace("fm", "fm_thumb");
  return url;
};

export const printDocument = (printId) => {
  var content = document.getElementById(printId);
  var pri = document.getElementById("print_frame").contentWindow;
  pri.document.open();
  pri.document.write(cusotmCssStyles.default);
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
};

export function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function jsonToQuery(params) {
  return qs.stringify(params);
}
/**
 * setLs
 * to store the user inputs into localStorage
 **/
export function setLs(key, value) {
  localStorage.setItem(key, value);
}

/**
 * getLs
 * to get the stored values from localStorage
 **/
export function getLs(key) {
  return localStorage.getItem(key) || false;
}

export function getAscSortOrder(prop, asNum = false) {
  return function (a, b) {
    if (asNum) {
      if (parseFloat(a[prop]) > parseFloat(b[prop])) {
        return 1;
      } else if (parseFloat(a[prop]) < parseFloat(b[prop])) {
        return -1;
      }
    } else {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
    }
    return 0;
  };
}

/**
 * getDescSortOrder
 * to sort an array by particular field
 * @param Property of an array
 * @return int
 */
export function getDescSortOrder(prop, asNum = false) {
  return function (a, b) {
    if (asNum) {
      if (parseFloat(a[prop]) < parseFloat(b[prop])) {
        return 1;
      } else if (parseFloat(a[prop]) > parseFloat(b[prop])) {
        return -1;
      }
    } else {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
    }
    return 0;
  };
}

/**
 * removeBothSlash
 * to remove the first and last slash from a string
 * @param1 $string
 * @return String
 */
export function removeBothSlash(str) {
  return str.replace(/^\/|\/$/g, "");
}

/**
 * getAcronym
 * to get first letter of each word in a string
 * @param1 stringValue
 * @param2 noOfChars
 * @return String
 */
export function getAcronym(stringValue, noOfChars = 1) {
  try {
    const str = stringValue; // "Java Script Object Notation";
    const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    const acronym = matches.join(""); // JSON

    return acronym.slice(0, noOfChars);
  } catch (error) {}
}

/**
 * capitalizeFirst
 * to capitalize the first letter of the word
 * @param1 $str (string)
 * @return string
 */
export function capitalizeFirst(str) {
  if (str == "" || str == null || str == " ") {
    return "";
  }

  str = str.toLowerCase();
  //return str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

/**
 * upperCase
 * to conver the string to upper case
 * @param1 $str (string)
 * @return string
 */
export function upperCase(str) {
  if (str == "" || str == null || str == " " || str.length < 1) return "";
  return str.toString().toUpperCase();
}

/**
 * lowerCase
 * to conver the string to lower case
 * @param1 $str (string)
 * @return string
 */
export function lowerCase(str) {
  if (str == "" || str == null || str == " ") return "";
  return str.toString().toLowerCase();
}

/**
 * makeUrl
 * to convert the string into url
 * to remove all the special characters and remove space
 *
 * @param1 $str
 * @return String
 */
export function makeUrl(str) {
  try {
    str = str.replace(/[&\/\\#,+()$~%.\'":*?<>{}]/g, "");
    str = str.replace(/ /g, "-");
    return str.toLowerCase();
  } catch (error) {
    //console.log(error);
  }
}

export function groupByMultiple(array, f) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export function groupBySingleData(array, groupKey, concatKey) {
  const groups = {};

  array.forEach((item) => {
    const groupValue = item[groupKey];

    // If the group for this key doesn't exist, create it
    if (!groups[groupValue]) {
      groups[groupValue] = { ...item, [concatKey]: item[concatKey] };
    } else {
      // If the group exists, concatenate the specified property
      groups[groupValue][concatKey] += ` – ${item[concatKey]}`;
    }
  });

  // Return the grouped data as an array
  return Object.values(groups);
}

export function filterArroyOfItems(arr, query) {
  return arr.filter((el) => el.toLowerCase().includes(query.toLowerCase()));
}

export function findCommonElement(array1, array2) {
  // Loop for array1
  for (let i = 0; i < array1.length; i++) {
    // Loop for array2
    for (let j = 0; j < array2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (upperCase(array1[i]) === upperCase(array2[j])) {
        // Return if common element found
        return true;
      }
    }
  }

  // Return if no common element exist
  return false;
}
/**
 * yesorNo
 * to get the value yes or no form the boolean
 * @param1 $val (bool) true,false, 1,0
 * @param2 $withColor (true,false) default false
 * @return String
 */
export function yesorNo(val) {
  if (val == "" || val == null || val == " ") return "No";
  if (val == "1" || val == true) return "Yes";
  return "No";
}

/**
 * isNullOrEmpty
 * to check the given value is null or empty
 * @param $val
 * @return Boolean
 */
export function isNullOrEmpty(val) {
  try {
    if (val == "" || val == null || val == " " || val == NaN) return false;
    return true;
  } catch (error) {
    //console.log(error);
    return true;
  }
}

/**
 * calculateMessageCount
 * to calculate letters count
 * @param1 $textArea
 * @param2 $displayArea
 * @return HTML
 */
export function calculateMsgCount(textAreaId, divId) {
  try {
    const singleSmsLength = 160;
    const ele = document.getElementById(textAreaId);
    const charLength = ele.value.length;
    const msgCount = Math.ceil(charLength / singleSmsLength);
    document.getElementById(divId).innerHTML = `${charLength} / ${msgCount}`;
  } catch (error) {
    //console.log(error);
  }
}

/**
 * momentDate
 * to convert a date format using moment
 * @param1 $date
 * @param2 $format
 * @return date
 */
export function momentDate(value, toFormat = "DD/MMM/YYYY", asString = false) {
  try {
    if (
      value == "0000-00-00" ||
      value == "" ||
      value == " " ||
      value == null ||
      value == NaN
    ) {
      return "";
    }

    // var d = new Date(value).getTime();
    return asString
      ? moment(value).locale("en-US").format(toFormat)
      : moment(value).format(toFormat);
  } catch (error) {
    //console.log(error);
    return "";
  }
}

export function momentAddDays(
  startDate,
  daysToAdd = 1,
  toFormat = "DD/MMM/YYYY"
) {
  try {
    // var d = new Date(value).getTime();
    return moment(startDate, toFormat)
      .add(daysToAdd - 1, "days")
      .format(toFormat);
  } catch (error) {
    //console.log(error);
    return "";
  }
}

export function momentSubtractDays(
  startDate,
  daysToAdd = 1,
  toFormat = "DD/MMM/YYYY"
) {
  try {
    // var d = new Date(value).getTime();
    return moment(startDate, toFormat)
      .subtract(daysToAdd - 1, "days")
      .format(toFormat);
  } catch (error) {
    //console.log(error);
    return "";
  }
}

/**
 * integerKeyPress
 * to handle textbox key press event and check whether the input is integer or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerKeyPress(e) {
  if (e.which != 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * decimalKeyPress
 * to handle textbox key press event and check whether the input is decimal or not
 *
 * @param EVENT
 * @return NULL
 */
export function decimalKeyPress(e) {
  if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Decimal numbers only");
    e.preventDefault();
    return false;
  }
  if (e.which == 46 && e.target.value.indexOf(".") != -1) {
    // showAdvice(this, "Only one period allowed in decimal numbers");
    e.preventDefault();
    return false; // only one decimal allowed
  }
}

/**
 * lettersOnly
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function lettersOnly(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * nameWithDotAndHypen
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function nameWithDotAndHypen(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    e.which != 45 &&
    e.which != 46 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * integerIndMobile
 * to handle textbox key press event and check whether the input is integer and less than 10 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndMobile(e) {
  const len = e.target.value.length;
  if (len >= 10) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerAadhar(e) {
  const len = e.target.value.length;
  if (len >= 12) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerGst(e) {
  const len = e.target.value.length;
  if (len >= 15) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function removeExtension(filename) {
  if (filename && filename != "" && filename != null && filename.length > 3)
    return filename.split(".").slice(0, -1).join(".");
}

export function getFileExtension(item) {
  let paths = removeBothSlash(item).split("/");
  let extension = item.substring(item.lastIndexOf("."));
  //let extension = item.split(".").slice(0, -1).join(".");

  return extension.replace(".", "");

  //return fileType;
}

export function calculateAge(dob) {
  try {
    var a = moment();
    var b = moment(moment(dob), "YYYY");
    var diff = a.diff(b, "years");
    return diff;
  } catch (er) {}
}

export function getHoursAsArray(val) {
  let array = [];
  for (let i = 1; i <= val; i++) array.push(i);
  return array;
}

export function replaceBulk(str, findArray, replaceArray) {
  var i,
    regex = [],
    map = {};
  for (i = 0; i < findArray.length; i++) {
    regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
    map[findArray[i]] = replaceArray[i];
  }
  regex = regex.join("|");
  str = str.replace(new RegExp(regex, "g"), function (matched) {
    return map[matched];
  });
  return str;
}

/**
 * numberToWords
 * to convert number to words
 *
 * @param1 number (int)
 * @return string
 */
export function numberToWords(amount) {
  if (!amount) return "";
  const words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  const atemp = amount.split(".");
  const number = atemp[0].split(",").join("");
  const n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
    const n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    const received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += `${words[value]} `;
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

export function testResult(
  condition,
  fromRange,
  toRange,
  result,
  gender = false
) {
  if (!condition) return true;

  try {
    var from = fromRange; //(gender=='F') ? test.female_range_from : test.range_from;
    var to = toRange; //(gender=='F') ? test.female_range_to : test.range_to;
    var cond = condition; //(gender=='F') ? test.female_reference_condition : test.reference_condition;

    if (cond == "Nothing" || cond == "Nill") {
      return false;
    }

    if (cond == "Equal") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Contain") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Less_Than") {
      return parseFloat(result) < parseFloat(from) ||
        parseFloat(result) < parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Greater_Than") {
      return parseFloat(result) > parseFloat(from) ||
        parseFloat(result) > parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Between") {
      return parseFloat(result) >= parseFloat(from) &&
        parseFloat(result) <= parseFloat(to)
        ? true
        : false;
    }
  } catch (er) {}
}

export function getDrCrColor(amt) {
  amt = parseFloat(amt);
  if (amt == 0) return "text-dark";
  else if (amt > 0) return "text-dark-success";
  else if (amt < 0) return "text-dark-danger";
}

export function typeName(str) {
  if (str && str != "" && str != null && str != NaN) {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    //return str.charAt(0).toUpperCase() + str.slice(1);
    return str.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }
  return str;
}

export function startDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format(toFormat);
}

export function endDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return moment(lastDay).format(toFormat);
}

export function emptyRowsToAdd() {
  let height = window.outerHeight;
  return Math.round((window.outerHeight - 250) / 20).toFixed(0);
}

export function queryParam(search = "") {
  let rv = [];
  if (search.length < 1) {
    return rv;
  }
  let str = search; //props.location.search;
  str = str.replace(/\?/g, "");
  let ar = str.split("&");
  if (ar && ar.length > 0) {
    ar.map((item, i) => {
      let m = item.split("=");
      rv[m[0]] = m[1];
    });
  }
  return rv;
}

export function formToObject(form) {
  const data = new FormData(form);
  const value = Object.fromEntries(data.entries());
  return value;
}

export function yearBySem(num, asText = false, asNum = false, asRoman = false) {
  if (asNum) {
    if (num == "1" || num == "2") return asRoman ? "I" : "1";
    else if (num == "3" || num == "4") return asRoman ? "II" : "2";
    else if (num == "5" || num == "6") return asRoman ? "III" : "3";
    else if (num == "7" || num == "8") return asRoman ? "IV" : "4";
    else if (num == "9" || num == "10") return asRoman ? "V" : "5";
    else if (num == "11" || num == "12") return asRoman ? "VI" : "6";
    else return "";
  }
  if (num == "1" || num == "2") return asText ? "First Year" : "I-Year";
  else if (num == "3" || num == "4") return asText ? "Second Year" : "II-Year";
  else if (num == "5" || num == "6") return asText ? "Third Year" : "III-Year";
  else if (num == "7" || num == "8") return asText ? "Fourth Year" : "IV-Year";
  else if (num == "9" || num == "10") return asText ? "Fifth Year" : "V-Year";
  else if (num == "11" || num == "12") return asText ? "Sixth Year" : "VI-Year";
  else return "";
}

export function semesterValue(num, asNum = false) {
  if (num == "1") return asNum ? "SEM-1" : "SEM-I";
  else if (num == "2") return asNum ? "SEM-2" : "SEM-II";
  else if (num == "3") return asNum ? "SEM-3" : "SEM-III";
  else if (num == "4") return asNum ? "SEM-4" : "SEM-IV";
  else if (num == "5") return asNum ? "SEM-5" : "SEM-V";
  else if (num == "6") return asNum ? "SEM-6" : "SEM-VI";
  else if (num == "7") return asNum ? "SEM-7" : "SEM-VII";
  else if (num == "8") return asNum ? "SEM-8" : "SEM-VIII";
  else if (num == "9") return asNum ? "SEM-9" : "SEM-IX";
  else if (num == "10") return asNum ? "SEM-10" : "SEM-X";
  else if (num == "11") return asNum ? "SEM-11" : "SEM-XI";
  else if (num == "12") return asNum ? "SEM-12" : "SEM-XII";
}

export function timeTableDayFromNumber(num, type = false) {
  if (num == "1") return type ? "Day 1" : "Mon";
  else if (num == "2") return type ? "Day 2" : "Tue";
  else if (num == "3") return type ? "Day 3" : "Wed";
  else if (num == "4") return type ? "Day 4" : "Thu";
  else if (num == "5") return type ? "Day 5" : "Fri";
  else if (num == "6") return type ? "Day 6" : "Sat";
}

export function customSorting(
  arrayOfDatas,
  sortOrderDatas,
  sortFieldName = "name"
) {
  try {
    const sortByObject = sortOrderDatas.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    const customSort = arrayOfDatas.sort(
      (a, b) => sortByObject[a[sortFieldName]] - sortByObject[b[sortFieldName]]
    );

    return customSort;
  } catch (err) {
    console.log(err);
    return arrayOfDatas;
  }
}

export function yearByBatch(batch, maxSem = 6, asText = false) {
  let currentMonth = moment(new Date()).format("MM");
  let currentYear = moment(new Date()).format("YYYY");
  if (parseInt(currentMonth) < 6) currentYear = parseInt(currentYear) - 1;
  let b = batch.split("-");
  if (b && b.length > 0) {
    if (b[0] == currentYear)
      return asText == "num" ? "I" : asText ? "First Year" : "I-Year";
    else if (b[0] == currentYear - 1 && maxSem > 2)
      return asText == "num" ? "II" : asText ? "Second Year" : "II-Year";
    else if (b[0] == currentYear - 2 && maxSem > 4)
      return asText == "num"
        ? "III"
        : asText && maxSem == 6
        ? "Final Year "
        : asText
        ? "Thir Year"
        : "III-Year";
    else if (b[0] == currentYear - 3 && maxSem > 6)
      return asText == "num" ? "IV" : asText ? "Fourth Year" : "IV-Year";
    else if (b[0] == currentYear - 4 && maxSem > 8)
      return asText == "num"
        ? "V"
        : asText && maxSem == 10
        ? "Final Year"
        : asText
        ? "Fitfth Year"
        : "V-Year";
    else if (b[0] == currentYear - 5 && maxSem > 10)
      return asText == "num"
        ? "VI"
        : asText && maxSem == 12
        ? "Final Year"
        : asText
        ? "Sixth Year"
        : "VI-Year";
  } else return "";
}

export function objectToQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function localTime(time) {
  if (!time || time.length < 1) return null;
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function bulkReplace(str, findArray, replaceArray) {
  try {
    var i,
      regex = [],
      map = {};
    for (i = 0; i < findArray.length; i++) {
      regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
      map[findArray[i]] = replaceArray[i];
    }
    regex = regex.join("|");
    str = str.replace(new RegExp(regex, "g"), function (matched) {
      return map[matched];
    });
    return str;
  } catch (er) {}
}

export function getDaysBetweenTwoDates(start, end) {
  try {
    /*var start = moment("2016-09-01"), // Sept. 1st
    end = moment("2016-11-02"), // Nov. 2nd*/
    start = moment(start);
    end = moment(end);
    var day = 0; // Sunday
    var result = [];
    var current = start.clone();

    while (current.day(7 + day).isBefore(end)) {
      result.push(current.clone());
    }
    return result;
  } catch (error) {}
}

export function localSettingsValues(key, defaultReturn = false) {
  try {
    /*let settings =
      getLs("adm_settings") && getLs("adm_settings") !== "undefined"
        ? JSON.parse(getLs("adm_settings"))
        : [];*/
    let settings = [];
    if (getLs("adm_settings") && getLs("adm_settings") !== "undefined") {
      let set = getLs("adm_settings");
      if (!set.includes("{")) {
        let decoded = jwt.decode(set, PS_WEB);
        settings = decoded;
      }
    }

    let m = settings && settings.find((item) => item.field_name == key);

    let v = m && m.field_name ? m.field_value : false;

    return v ? v : defaultReturn;
  } catch (er) {}
}

export function listMonths(startDate, endDate) {
  try {
    startDate = moment(startDate);
    endDate = moment(endDate);
    var betweenMonths = [];

    if (startDate < endDate) {
      var date = startDate.startOf("month");

      while (date < endDate.endOf("month")) {
        betweenMonths.push(date.format("YYYY-MM"));
        date.add(1, "month");
      }
      return betweenMonths;
    }
  } catch (er) {
    return [];
  }
}

// end date
//alert
// start date
export function lessThanToday(dt, alert = false, st = new Date()) {
  try {
    let start = moment(st);
    let end = moment(dt);
    if (start.diff(end) < 0) {
      if (alert)
        toast.error(alert === true ? "Enter Date less than today" : alert);
      return false;
    }
    return true;
  } catch (er) {
    return false;
  }
}

export function ParseFloat(str, length) {
  try {
    str = str.toString();
    if (str.match(/\./g)) str = str.slice(0, str.indexOf(".") + length + 1);
    return Number(str);
  } catch (er) {
    return parseFloat(str).toFixed(2);
  }
}

export function differenceBetweenTwoDates(
  startDate = new Date(),
  endDate = new Date(),
  diffBy = "days"
) {
  try {
    let diff = moment(startDate).diff(moment(endDate), diffBy);
    return diff;
  } catch (er) {
    return 0;
  }
}

export function calculateCourseDurationByBatch(batch) {
  try {
    let m = batch.split("-");
    let y = parseInt(m[1]) - parseInt(m[0]);
    return y;
  } catch (er) {
    return "-";
  }
}

/**
 * numberToMonth
 * to convert number to month
 *
 * @since 1.0
 * @param1 number
 * @return String
 */
export function numberToMonth(number) {
  var months = [];
  months["01"] = "January";
  months["02"] = "February";
  months["03"] = "March";
  months["04"] = "April";
  months["05"] = "May";
  months["06"] = "June";
  months["07"] = "July";
  months["08"] = "August";
  months["09"] = "September";
  months["1"] = "January";
  months["2"] = "February";
  months["3"] = "March";
  months["4"] = "April";
  months["5"] = "May";
  months["6"] = "June";
  months["7"] = "July";
  months["8"] = "August";
  months["9"] = "September";
  months["10"] = "October";
  months["11"] = "November";
  months["12"] = "December";

  return months[number];
}

export const genderShortValue = (gender) => {
  gender = lowerCase(gender);
  return gender == "male"
    ? "M"
    : gender == "female"
    ? "F"
    : gender == "tg"
    ? "TG"
    : "";
};

export const courseTypeShortName = (type, withBracket = true) => {
  type = upperCase(type);
  if (type == "REGULAR") return withBracket ? "(R)" : "R";
  else if (type == "SELF") return withBracket ? "(SF)" : "SF";
  else return "";
};

export const gendetShortCode = (gender, short = true) => {
  gender = upperCase(gender);
  if (short) {
    if (gender == "MALE") return "M";
    else if (gender == "FEMALE") return "F";
    else return gender;
  } else {
    return gender;
  }
};

export const courseName = (courseInfo, fullName = false) => {
  let rv = "";
  try {
    if (courseInfo.course_short_name && !fullName) {
      return courseInfo.course_short_name;
    } else if (courseInfo.course_shortname && !fullName) {
      return courseInfo.course_shortname;
    } else {
      let dname = courseInfo.degree_name || courseInfo.degreename || "";
      rv =
        dname +
        " - " +
        (courseInfo.course_name ||
          courseInfo.name ||
          courseInfo.coursename ||
          "");
      if (
        upperCase(courseInfo.course_type || courseInfo.coursetype) == "REGULAR"
      )
        rv += " (R)";
      else if (
        upperCase(courseInfo.course_type || courseInfo.coursetype) == "SELF"
      )
        rv += " (SF)";
    }
  } catch (er) {}
  return rv;
};

export const printedOnText = (time = new Date(), withOutText = false) => {
  return (
    <span style={{ float: "right", fontSize: "10px" }}>
      {!withOutText && "Printed on"} : {momentDate(time, "DD-MMM-YYYY hh:mm A")}
    </span>
  );
};

export const brandLogo = (lvalue = false) => {
  try {
    return lvalue
      ? `${baseUrl}${lvalue}`
      : `${baseUrl}${localSettingsValues("college_logo_square")}`;
  } catch (er) {}
};

export const cdnFileLink = (fielUrl) => {
  try {
    return `${siteUrl}/${SITE_FILE_DOWNLOAD_DIR}/${fielUrl}`;
  } catch (er) {}
};

export const studentNo = (inputValues) => {
  try {
    if (inputValues === false) {
      return localSettingsValues("primary_student_identity") == "admissionno"
        ? "Adm.No"
        : "Reg.No";
    }
    if (localSettingsValues("primary_student_identity") == "admissionno")
      return (
        upperCase(inputValues.admissionno) || upperCase(inputValues.registerno)
      );
    else
      return (
        upperCase(inputValues.registerno) || upperCase(inputValues.admissionno)
      );
  } catch (er) {}
};

export const commaFormatted = (amount, asFloat = false) => {
  try {
    if (
      amount == NaN ||
      amount == undefined ||
      amount.length < 1 ||
      parseFloat(amount) < 0
    )
      return asFloat ? "0.00" : "0";

    var afterDot = "";
    let m = parseFloat(amount).toFixed(2).split(".");
    var x = m[0];

    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (asFloat && m.length > 0) {
      res = res + "." + m[1];
    }

    return res;
  } catch (er) {
    console.log(er.messsage);
    return asFloat ? parseFloat(amount).toFixed(2) : amount;
  }
};

export const filterDataList = (dataSource, filterList, setFunt = false) => {
  try {
    let dat = [];
    let d = filterList;
    Object.keys(d).filter((item) => {
      if (d[item] && d[item] != "") dat[item] = d[item];
    });

    let m = dataSource.filter((o) =>
      Object.keys(dat).every((k) => upperCase(dat[k]) == upperCase(o[k]))
    );
    if (setFunt) setFunt(m);
    else return m;
  } catch (er) {
    console.log(er);
  }
};

export const DIOCESES_LIST = localSettingsValues(
  "adm_denomination_list",
  ""
)?.split(",");

export const PLACEMENT_INTERESET_LIST = localSettingsValues(
  "adm_placement_training_list",
  ""
)?.split(",");

export const ANNUAL_INCOME_RANGE = [
  "Less Than 80,000 INR",
  "80,000 to 200,000 INR (2 lakhs)",
  "200,000 to 500,000 INR (5 lakhs)",
  "500,000 to 800,000 INR (8 lakhs)",
  "800,000 INR and above",
];

export const SPORTS_LEVEL = ["DISTRICT", "STATE", "NATIONAL", "INTERNATIONAL"];

export const PARENTAL_STATUS = [
  "WITH PARENTS",
  "SINGLE PARENT - MOTHER ONLY",
  "SINGLE PARENT - FATHER ONLY",
  "ORPHAN",
];

export const WORK_NATURE = [
  "State Government Job",
  "Central Government Job",
  "Private Employee",
  "Own Business",
  "Others",
];

export const hscPartThreeScored = (item) => {
  let total = 0;
  if (upperCase(item.department_name).includes("TAMIL")) {
    total = item.tamil_scored;
  } else if (upperCase(item.department_name).includes("ENGLISH")) {
    total = item.english_scored;
  } else {
    total = item.part3_total;
  }
  total = parseFloat(total).toFixed(2);
  return total != "NaN" ? total : "";
};

export const hscPartThreePercentage = (item) => {
  let total = 0;
  if (upperCase(item.department_name).includes("TAMIL")) {
    total = item.tamil_scored;
  } else if (upperCase(item.department_name).includes("ENGLISH")) {
    total = item.english_scored;
  } else {
    total = item.part3_percentage;
  }
  total = parseFloat(total).toFixed(2);
  return total != "NaN" ? total : "";
};

export const COMMUNITY_FILTER_OPTIONS = [
  { text: "BC-BCM", value: "bc-bcm" },
  { text: "MBC-DNC", value: "mbc" },
  { text: "SC-SCA-ST", value: "sc-sca-st" },
  { text: "OverAll-Minority", value: "minority" },
  { text: "Minority-Dalit", value: "minority-dalit" },
  { text: "Minority-Non-Dalit", value: "minority-nondalit" },
  { text: "OverAll-Male", value: "male" },
  { text: "OverAll-Female", value: "female" },
];

export const getDenominationValue = (text) => {
  try {
    if (upperCase(text) == "ROMAN CATHOLIC") return "RC";
    if (upperCase(text) == "CSI-TVL") return "CSI-TVL";
    if (upperCase(text) == "CSI") return "CSI";
    if (upperCase(text) == "OTHERS") return "OTHER";
  } catch (er) {}
};

export const downloadUgApplication = (
  item,
  asView = false,
  onlyLink = false
) => {
  try {
    let link;
    if (S3_BUCKET_HOME_PATH === "dbcmaram/") {
      link = `${baseUrl}v1/admission/ug/ugpdf/new/${item.admission_year}/${
        item.uuid
      }/${item.application_code}?from=${S3_BUCKET_HOME_PATH.replace(
        /[\\/]/g,
        ""
      )}`;
    } else {
      link = `${baseUrl}v1/admission/ug/ugpdf/${
        item.admission_year || item.college_year
      }/${item.uuid}/${
        item.application_code || item.application_no
      }?from=${S3_BUCKET_HOME_PATH.replace(/[\\/]/g, "")}`;
    }
    if (asView) return link + "&view=1";
    if (onlyLink) return link;

    var a = document.createElement("a");
    a.setAttribute("download", item.application_code);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (er) {
    console.log(er);
  }
};
export const downloadPgApplication = (
  item,
  asView = false,
  onlyLink = false
) => {
  try {
    let link;
    if (S3_BUCKET_HOME_PATH === "dbcmaram/") {
      link = `${baseUrl}v1/admission/pg/pgpdf/new/${item.admission_year}/${
        item.uuid
      }/${item.application_code}?from=${S3_BUCKET_HOME_PATH.replace(
        /[\\/]/g,
        ""
      )}`;
    } else {
      link = `${baseUrl}v1/admission/pg/pgpdf/${item.admission_year}/${
        item.uuid
      }/${item.application_code}?from=${S3_BUCKET_HOME_PATH.replace(
        /[\\/]/g,
        ""
      )}`;
    }
    if (asView) return link + "&view=1";
    if (onlyLink) return link;

    var a = document.createElement("a");
    a.setAttribute("download", item.application_code);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (er) {
    console.log(er);
  }
};

export const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const removeFieldFromObjects = (objects, fieldToRemove) => {
  return objects.map((obj) => {
    const { [fieldToRemove]: _, ...rest } = obj; // Destructure and exclude the field
    return rest; // Return the new object without the field
  });
};

export const sortCommaSeparateValues = (inputValue) => {
  try {
    // Split the comma-separated string into an array
    const valuesArray = inputValue.split(",");

    // Sort the array in ascending order
    valuesArray.sort((a, b) => a.localeCompare(b));

    // Join the sorted array back into a comma-separated string
    const sortedValues = valuesArray.join(",");

    // Update state with the sorted values
    return sortedValues;
  } catch (er) {}
};

export const formatNumber = (num, decimalPlaces = 2) => {
  // Use toFixed to round the number
  let rounded = num.toFixed(decimalPlaces);
  // Convert to float to remove trailing zeros
  return parseFloat(rounded);
};

export const printSubjectName = (item, withCode = false) => {
  try {
    let v = "";
    if (withCode) v = item.subject_code + "-";
    v += item.subject_name || "";
    if (item.short_name && item.short_name.length > 0)
      v += "-" + item.short_name;
    return v;
  } catch (er) {}
};

export const _ROOM_TYPES = ["class_room", "hall", "lab"];
export const downloadFile = async (fileUrl, fileName = "download") => {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob(); // Get the file as a blob

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Use the provided file name or a default name

    // Append the link to the DOM and trigger a click
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the blob URL
    link.remove();
    window.URL.revokeObjectURL(url); // Free up memory
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
